import React, { useEffect } from 'react'
import SEO from '../../components/seo'

// import slugify from 'slugify';
import { graphql, Link } from 'gatsby'

import {
  Img,
  TypeChooserContain,
  Container,
  CollectionItems
} from '../../utils/styles'

import {
  GridContain,
  CollectionGrid,
  Product,
  Title,
  PriceArea,
  Button,
  PriceTag,
  DiscountedPriceTag,
  CollectionReviewStars
} from '../../components/BestSellers/styles'

import { Price } from '../../components/Price'

import {
  ProductStarsWidget,
  useYotpo,
} from '../../components/Reviews'

import salesBanner from '../../images/sales/arms-banner.jpg'
import bundlesFooter from '../../images/sales/bundles-footer.jpg'

// import salesBanner from '../../images/sales/sale.jpg';
// import bundlesBanner from '../../images/sales/bundles-header.jpg';


// import { sortedTypes } from '../../constants/producttypes';

// const TypeColumn = () => (
//   <ul className="type-column">
//     { sortedTypes.map(x => (
//         <React.Fragment key={ x[0] }>
//           <li className="distinct-category" >
//             <Link to={`/collections/${slugify(x[0], {lower: true})}` } activeClassName="active">
//               {x[0]}
//             </Link>
//           </li>
//           { x[1].map(y => (
//             <li className="distinct-type" key={ y }>
//               <Link to={`/type/${slugify(y, {lower: true})}`} activeClassName="active">
//                 {y}
//               </Link>
//             </li>       
//           ) )}
//         </React.Fragment>
//       )
//     )}
//   </ul>
// )


const ProductGrid = ({ products }) => {
  return (
    <CollectionItems>
      <GridContain>
        <CollectionGrid>
        { products.map(({ id, handle, tags, title, parentVariant, images: [firstImage], variants: [firstVariant] }) => {
          const filteredTitle = title.replace('Copper Compression','');

          return (
          <Product key={ id } >
            <Link to={ `/products/${handle}/` }>
              { firstImage && firstImage.localFile &&
                (<Img
                  fluid={{ ...firstImage.localFile.childImageSharp.fluid, aspectRatio: 1/1 } }
                  alt={ handle }
                />) }
            </Link>
            <Link to={ `/products/${handle}/` }>
              <Title>{ filteredTitle }</Title>
              <CollectionReviewStars>
                <ProductStarsWidget handle={handle} parentVariant={parentVariant} />
              </CollectionReviewStars>
              <PriceArea>
                <Price variant={firstVariant} tags={tags} TagWrapper={PriceTag} TagInner={DiscountedPriceTag} />
                <Button>Buy Now</Button>
              </PriceArea>
            </Link>
          </Product>
        )}) }
        </CollectionGrid>
      </GridContain>
    </CollectionItems>
  )
}

const CollectionPage = ({ data, pageContext }) => {
  const collection = data.shopifyCollection
  const { products } = pageContext

  collection.products.map(product => {
    const localProduct = products.find(local => product.handle === local.handle);
    if (localProduct) {
      product.parentVariant = localProduct.localFile.parentVariant;
    }

    return product;
  })

  useEffect(useYotpo(true));

  return (
    <>
      <SEO title={ collection.title } description={ collection.description } />
      <Container>

        <Link to='/collections/arms'>
          <img alt="sales header" src={salesBanner} width="100%" />
        </Link>

        {/* {collection.title === "Sale" && 
          <img alt="sales header" src={salesBanner} width="100%" />
        }

        {collection.title === "Bundles" && 
          <img alt="bundles header" src={bundlesBanner} width="100%" />
        } */}

        <TypeChooserContain>
          <ProductGrid products={ collection.products } />
        </TypeChooserContain>
        {collection.title === "Bundles" && 
          <img alt="sales footer" src={bundlesFooter} width="100%" style={{marginBottom: '40px'}} />
        }
      </Container>
    </>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyCollection(handle: { eq: $handle }) {
      id
      title
      updatedAt
      shopifyId
      description
      descriptionHtml
      handle
      products {
        id
        title
        handle
        createdAt
        availableForSale
        tags
        images {
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        variants {
          price
          compareAtPrice
          product {
            handle
          }
        }
      }
    }
  }
`

export default CollectionPage
